import { useConfirm } from '@/services/confirm/ConfirmationService';
import { FC } from 'react';
import { LinkProps, useLinkClickHandler } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface Props extends LinkProps {
}

export const ConfirmedLink: FC<Props> = ({ className, to, replace = false, state, target, ...props }) => {
  const handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target
  });

  const { requestConfirmation } = useConfirm(state => ({ requestConfirmation: state.requestConfirmation }));

  return (
    <a
      {...props}
      className={twMerge(className, 'cursor-pointer')}
      onClick={event => {
        requestConfirmation(() => handleClick(event));
      }}
    ></a>
  );
};
