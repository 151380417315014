import { DropdownOption } from '@/components/form/GridSelect';
import * as AI from 'react-icons/ai'; //MIT
import * as BI from 'react-icons/bi'; //MIT
import * as PI from 'react-icons/pi'; //MIT
import * as TB from 'react-icons/tb'; //MIT
import * as RI from 'react-icons/ri'; // Apache 2
import * as BS from 'react-icons/bs'; // MIT
import * as IO from 'react-icons/io'; // MIT
import * as MD from 'react-icons/md'; // Apache 2
import * as HI from 'react-icons/hi'; // MIT

export const getOption = (optionsArray: DropdownOption[], optionId: any) => {
  if (optionsArray && optionId) {
    return optionsArray.find(opt => opt.id === optionId);
  }
};

export interface Icon {
  id: string;
  name: string;
  value: JSX.Element;
  svgPaths: string[];
  svgViewboxSize: number;
}

export const iconArray: Icon[] = [
  {
    id: '1',
    value: <BS.BsCircleFill />,
    name: 'Circle',
    svgPaths: ['M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16'],
    svgViewboxSize: 16
  },
  {
    id: '2',
    value: <IO.IoMdLock />,
    name: 'Lock',
    svgPaths: [
      'M368,192H352V112a96,96,0,1,0-192,0v80H144a64.07,64.07,0,0,0-64,64V432a64.07,64.07,0,0,0,64,64H368a64.07,64.07,0,0,0,64-64V256A64.07,64.07,0,0,0,368,192Zm-48,0H192V112a64,64,0,1,1,128,0Z'
    ],
    svgViewboxSize: 512
  },
  {
    id: '3',
    value: <BI.BiSolidShow />,
    name: 'Bell',
    svgPaths: [
      'M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z',
      'M12 10c-1.084 0-2 .916-2 2s.916 2 2 2 2-.916 2-2-.916-2-2-2z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '4',
    value: <RI.RiLeafFill />,
    name: 'Leaf',
    svgPaths: [
      'M20.998 3V5C20.998 14.6274 15.6255 19 8.99805 19L7.0964 18.9999C7.3079 15.9876 8.24541 14.1648 10.6939 11.9989C11.8979 10.9338 11.7965 10.3189 11.2029 10.6721C7.1193 13.1016 5.09114 16.3862 5.00119 21.6302L4.99805 22H2.99805C2.99805 20.6373 3.11376 19.3997 3.34381 18.2682C3.1133 16.9741 2.99805 15.2176 2.99805 13C2.99805 7.47715 7.4752 3 12.998 3C14.998 3 16.998 4 20.998 3Z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '5',
    value: <MD.MdFace />,
    name: 'Face',
    svgPaths: [
      'M9 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm6 0c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37C11.07 8.33 14.05 10 17.42 10c.78 0 1.53-.09 2.25-.26.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '6',
    value: <BS.BsFillBookmarkFill />,
    name: 'Bookmark',
    svgPaths: ['M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2'],
    svgViewboxSize: 16
  },
  {
    id: '7',
    value: <BS.BsHeart />,
    name: 'Heart',
    svgPaths: [
      'm8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15'
    ],
    svgViewboxSize: 16
  },
  {
    id: '8',
    value: <BS.BsFillBagFill />,
    name: 'Bag',
    svgPaths: [
      'M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4z'
    ],
    svgViewboxSize: 16
  },
  {
    id: '9',
    value: <BI.BiSearchAlt2 />,
    name: 'Search',
    svgPaths: [
      'M19.023 16.977a35.13 35.13 0 0 1-1.367-1.384c-.372-.378-.596-.653-.596-.653l-2.8-1.337A6.962 6.962 0 0 0 16 9c0-3.859-3.14-7-7-7S2 5.141 2 9s3.14 7 7 7c1.763 0 3.37-.66 4.603-1.739l1.337 2.8s.275.224.653.596c.387.363.896.854 1.384 1.367l1.358 1.392.604.646 2.121-2.121-.646-.604c-.379-.372-.885-.866-1.391-1.36zM9 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '10',
    value: <AI.AiFillTool />,
    name: 'Tool',
    svgPaths: [
      'M865.3 244.7c-.3-.3-61.1 59.8-182.1 180.6l-84.9-84.9 180.9-180.9c-95.2-57.3-217.5-42.6-296.8 36.7A244.42 244.42 0 0 0 419 432l1.8 6.7-283.5 283.4c-6.2 6.2-6.2 16.4 0 22.6l141.4 141.4c6.2 6.2 16.4 6.2 22.6 0l283.3-283.3 6.7 1.8c83.7 22.3 173.6-.9 236-63.3 79.4-79.3 94.1-201.6 38-296.6z'
    ],
    svgViewboxSize: 1024
  },
  {
    id: '11',
    value: <HI.HiPhone />,
    name: 'Phone',
    svgPaths: [
      'M1.5 4.5C1.5 2.84315 2.84315 1.5 4.5 1.5H5.87163C6.732 1.5 7.48197 2.08556 7.69064 2.92025L8.79644 7.34343C8.97941 8.0753 8.70594 8.84555 8.10242 9.29818L6.8088 10.2684C6.67447 10.3691 6.64527 10.5167 6.683 10.6197C7.81851 13.7195 10.2805 16.1815 13.3803 17.317C13.4833 17.3547 13.6309 17.3255 13.7316 17.1912L14.7018 15.8976C15.1545 15.2941 15.9247 15.0206 16.6566 15.2036L21.0798 16.3094C21.9144 16.518 22.5 17.268 22.5 18.1284V19.5C22.5 21.1569 21.1569 22.5 19.5 22.5H17.25C8.55151 22.5 1.5 15.4485 1.5 6.75V4.5Z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '12',
    value: <BS.BsFillQuestionSquareFill />,
    name: 'Question',
    svgPaths: [
      'M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z'
    ],
    svgViewboxSize: 16
  },
  {
    id: '13',
    value: <BI.BiSolidMoon />,
    name: 'Moon',
    svgPaths: [
      'M12 11.807A9.002 9.002 0 0 1 10.049 2a9.942 9.942 0 0 0-5.12 2.735c-3.905 3.905-3.905 10.237 0 14.142 3.906 3.906 10.237 3.905 14.143 0a9.946 9.946 0 0 0 2.735-5.119A9.003 9.003 0 0 1 12 11.807z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '14',
    value: <RI.RiCupFill />,
    name: 'Cup',
    svgPaths: [
      'M5 3H20C21.1046 3 22 3.89543 22 5V8C22 9.10457 21.1046 10 20 10H18V13C18 15.2091 16.2091 17 14 17H8C5.79086 17 4 15.2091 4 13V4C4 3.44772 4.44772 3 5 3ZM18 5V8H20V5H18ZM2 19H20V21H2V19Z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '15',
    value: <MD.MdCake />,
    name: 'Cake',
    svgPaths: [
      'M12 6c1.11 0 2-.9 2-2 0-.38-.1-.73-.29-1.03L12 0l-1.71 2.97c-.19.3-.29.65-.29 1.03 0 1.1.9 2 2 2zm4.6 9.99l-1.07-1.07-1.08 1.07c-1.3 1.3-3.58 1.31-4.89 0l-1.07-1.07-1.09 1.07C6.75 16.64 5.88 17 4.96 17c-.73 0-1.4-.23-1.96-.61V21c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-4.61c-.56.38-1.23.61-1.96.61-.92 0-1.79-.36-2.44-1.01zM18 9h-5V7h-2v2H6c-1.66 0-3 1.34-3 3v1.54c0 1.08.88 1.96 1.96 1.96.52 0 1.02-.2 1.38-.57l2.14-2.13 2.13 2.13c.74.74 2.03.74 2.77 0l2.14-2.13 2.13 2.13c.37.37.86.57 1.38.57 1.08 0 1.96-.88 1.96-1.96V12C21 10.34 19.66 9 18 9z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '16',
    value: <IO.IoMdStar />,
    name: 'Star',
    svgPaths: [
      'M394,480a16,16,0,0,1-9.39-3L256,383.76,127.39,477a16,16,0,0,1-24.55-18.08L153,310.35,23,221.2A16,16,0,0,1,32,192H192.38l48.4-148.95a16,16,0,0,1,30.44,0l48.4,149H480a16,16,0,0,1,9.05,29.2L359,310.35l50.13,148.53A16,16,0,0,1'
    ],
    svgViewboxSize: 512
  },
  {
    id: '17',
    value: <PI.PiFish />,
    name: 'Fish',
    svgPaths: [
      'M8,175.87l56.07,16.06,16,56.07,24-56.07C242.67,188.64,225.91,67.85,220.27,41.85a8,8,0,0,0-6.13-6.13c-26-5.63-146.74-22.37-150.07,116.15Z',
      'M185.82,167.62A44,44,0,0,1,136.2,119.8,44,44,0,0,1,88.38,70.21'
    ],
    svgViewboxSize: 256
  },
  {
    id: '18',
    value: <BS.BsTicket />,
    name: 'Ticket',
    svgPaths: [
      'M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6zM1.5 4a.5.5 0 0 0-.5.5v1.05a2.5 2.5 0 0 1 0 4.9v1.05a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1.05a2.5 2.5 0 0 1 0-4.9V4.5a.5.5 0 0 0-.5-.5z'
    ],
    svgViewboxSize: 16
  },
  {
    id: '19',
    value: <RI.RiKeyFill />,
    name: 'Key',
    svgPaths: [
      'M17 14H12.6586C11.8349 16.3304 9.61244 18 7 18C3.68629 18 1 15.3137 1 12C1 8.68629 3.68629 6 7 6C9.61244 6 11.8349 7.66962 12.6586 10H23V14H21V18H17V14ZM7 14C8.10457 14 9 13.1046 9 12C9 10.8954 8.10457 10 7 10C5.89543 10 5 10.8954 5 12C5 13.1046 5.89543 14 7 14Z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '20',
    value: <MD.MdOutlineLightbulb />,
    name: 'Light bulb',
    svgPaths: [
      'M9 21c0 .5.4 1 1 1h4c.6 0 1-.5 1-1v-1H9v1zm3-19C8.1 2 5 5.1 5 9c0 2.4 1.2 4.5 3 5.7V17c0 .5.4 1 1 1h6c.6 0 1-.5 1-1v-2.3c1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '21',
    value: <MD.MdStore />,
    name: 'Store',
    svgPaths: ['M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z'],
    svgViewboxSize: 24
  },
  {
    id: '22',
    value: <IO.IoIosRestaurant />,
    name: 'Restaurant',
    svgPaths: [
      'M357.57,223.94a79.48,79.48,0,0,0,56.58-23.44l77-76.95c6.09-6.09,6.65-16,.85-22.39a16,16,0,0,0-23.17-.56L400.2,169.18a12.29,12.29,0,0,1-17.37,0c-4.79-4.78-4.53-12.86.25-17.64l68.33-68.33a16,16,0,0,0-.56-23.16A15.62,15.62,0,0,0,440.27,56a16.71,16.71,0,0,0-11.81,4.9l-68.27,68.26a12.29,12.29,0,0,1-17.37,0c-4.78-4.78-4.53-12.86.25-17.64L411.4,43.21a16,16,0,0,0-.56-23.16A15.62,15.62,0,0,0,400.26,16a16.73,16.73,0,0,0-11.81,4.9L311.5,97.85a79.49,79.49,0,0,0-23.44,56.59v8.23A16,16,0,0,1,283.37,174l-35.61,35.62a4,4,0,0,1-5.66,0L68.82,36.33a16,16,0,0,0-22.58-.06C31.09,51.28,23,72.47,23,97.54c-.1,41.4,21.66,89,56.79,124.08l85.45,85.45A64.79,64.79,0,0,0,211,326a64,64,0,0,0,16.21-2.08,16.24,16.24,0,0,1,4.07-.53,15.93,15.93,0,0,1,10.83,4.25l11.39,10.52a16.12,16.12,0,0,1,4.6,11.23v5.54a47.73,47.73,0,0,0,13.77,33.65l90.05,91.57.09.1a53.29,53.29,0,0,0,75.36-75.37L302.39,269.9a4,4,0,0,1,0-5.66L338,228.63a16,16,0,0,1,11.32-4.69Z',
      'M211,358a97.32,97.32,0,0,1-68.36-28.25l-13.86-13.86a8,8,0,0,0-11.3,0l-85,84.56c-15.15,15.15-20.56,37.45-13.06,59.29a30.63,30.63,0,0,0,1.49,3.6C31,484,50.58,496,72,496a55.68,55.68,0,0,0,39.64-16.44L225,365.66a4.69,4.69,0,0,0,1.32-3.72l0-.26a4.63,4.63,0,0,0-5.15-4.27A97.09,97.09,0,0,1,211,358Z'
    ],
    svgViewboxSize: 512
  },
  {
    id: '23',
    value: <MD.MdOutlineCookie />,
    name: 'Cookie',
    svgPaths: [
      'M21.95,10.99c-1.79-0.03-3.7-1.95-2.68-4.22c-2.98,1-5.77-1.59-5.19-4.56C6.95,0.71,2,6.58,2,12c0,5.52,4.48,10,10,10 C17.89,22,22.54,16.92,21.95,10.99z M8.5,15C7.67,15,7,14.33,7,13.5S7.67,12,8.5,12s1.5,0.67,1.5,1.5S9.33,15,8.5,15z M10.5,10 C9.67,10,9,9.33,9,8.5S9.67,7,10.5,7S12,7.67,12,8.5S11.33,10,10.5,10z M15,16c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1 C16,15.55,15.55,16,15,16z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '24',
    value: <MD.MdOutdoorGrill />,
    name: 'Grill',
    svgPaths: [
      'M17,22c1.66,0,3-1.34,3-3s-1.34-3-3-3c-1.3,0-2.4,0.84-2.82,2H9.14l1.99-3.06C11.42,14.98,11.71,15,12,15 s0.58-0.02,0.87-0.06l1.02,1.57c0.42-0.53,0.96-0.95,1.6-1.21l-0.6-0.93C17.31,13.27,19,10.84,19,8H5c0,2.84,1.69,5.27,4.12,6.37 l-3.95,6.08c-0.3,0.46-0.17,1.08,0.29,1.38h0c0.46,0.3,1.08,0.17,1.38-0.29l1-1.55h6.34C14.6,21.16,15.7,22,17,22z M17,18 c0.55,0,1,0.45,1,1c0,0.55-0.45,1-1,1s-1-0.45-1-1C16,18.45,16.45,18,17,18z"/><path d="M9.41,7h1c0.15-1.15,0.23-1.64-0.89-2.96C9.1,3.54,8.84,3.27,9.06,2H8.07C7.86,3.11,8.1,4.05,8.96,4.96 C9.18,5.2,9.75,5.63,9.41,7z"/><path d="M11.89,7h1c0.15-1.15,0.23-1.64-0.89-2.96c-0.42-0.5-0.68-0.78-0.46-2.04h-0.99c-0.21,1.11,0.03,2.05,0.89,2.96 C11.67,5.2,12.24,5.63,11.89,7z"/><path d="M14.41,7h1c0.15-1.15,0.23-1.64-0.89-2.96C14.1,3.54,13.84,3.27,14.06,2h-0.99c-0.21,1.11,0.03,2.05,0.89,2.96 C14.18,5.2,14.75,5.63,14.41,7z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '25',
    value: <PI.PiCookingPot />,
    name: 'Camp cook',
    svgPaths: ['M48,80H208a8,8,0,0,1,8,8v96a24,24,0,0,1-24,24H64a24,24,0,0,1-24-24V88A8,8,0,0,1,48,80Z'],
    svgViewboxSize: 256
  },
  {
    id: '26',
    value: <TB.TbChefHat />,
    name: 'Chef',
    svgPaths: [
      'M12 3c1.918 0 3.52 1.35 3.91 3.151a4 4 0 0 1 2.09 7.723l0 7.126h-12v-7.126a4 4 0 1 1 2.092 -7.723a4 4 0 0 1 3.908 -3.151z',
      'M6.161 17.009l11.839 -.009'
    ],
    svgViewboxSize: 24
  },
  {
    id: '27',
    value: <PI.PiPizza />,
    name: 'Pizza',
    svgPaths: [
      'M12 21.5c-3.04 0 -5.952 -.714 -8.5 -1.983l8.5 -16.517l8.5 16.517a19.09 19.09 0 0 1 -8.5 1.983z',
      'M5.38 15.866a14.94 14.94 0 0 0 6.815 1.634a14.944 14.944 0 0 0 6.502 -1.479',
      'M13 11.01v-.01',
      'M11 14v-.01'
    ],
    svgViewboxSize: 24
  },
  {
    id: '28',
    value: <BS.BsCash />,
    name: 'Cash',
    svgPaths: [
      'M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4',
      'M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2z'
    ],
    svgViewboxSize: 16
  },
  {
    id: '29',
    value: <BS.BsPersonVcardFill />,
    name: 'Person card',
    svgPaths: [
      'M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5',
      'M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z'
    ],
    svgViewboxSize: 16
  },
  {
    id: '30',
    value: <MD.MdReceipt />,
    name: 'Receipt',
    svgPaths: [
      'M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '31',
    value: <PI.PiAirplane />,
    name: 'Airplane',
    svgPaths: ['M16 10h4a2 2 0 0 1 0 4h-4l-4 7h-3l2 -7h-4l-2 2h-3l2 -4l-2 -4h3l2 2h4l-2 -7h3z'],
    svgViewboxSize: 24
  },
  {
    id: '32',
    value: <MD.MdWaterDrop />,
    name: 'Chemical',
    svgPaths: ['M12,2c-5.33,4.55-8,8.48-8,11.8c0,4.98,3.8,8.2,8,8.2s8-3.22,8-8.2C20,10.48,17.33,6.55,12,2z M7.83,14 c0.37,0,0.67,0.26,0.74,0.62c0.41,2.22,2.28,2.98,3.64,2.87c0.43-0.02,0.79,0.32,0.79,0.75c0,0.4-0.32,0.73-0.72,0.75 c-2.13,0.13-4.62-1.09-5.19-4.12C7.01,14.42,7.37,14,7.83,14z'],
    svgViewboxSize: 24
  },
  {
    id: '33',
    value: <PI.PiAlarm />,
    name: 'Alarm',
    svgPaths: ['M12 13m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0', 'M12 10l0 3l2 0', 'M7 4l-2.75 2', 'M17 4l2.75 2'],
    svgViewboxSize: 24
  },
  {
    id: '34',
    value: <PI.PiDress />,
    name: 'Dress',
    svgPaths: ['M200,224a8,8,0,0,0,7.35-11.15L160,112l22.86-35.88a8,8,0,0,0,0-8.24L160,35.22,153,44a32,32,0,0,1-50,0l-7-8.77L73.14,67.88a8,8,0,0,0,0,8.24L96,112,48.66,212.85A8,8,0,0,0,56,224Z'],
    svgViewboxSize: 256
  },
  {
    id: '35',
    value: <IO.IoIosBeer />,
    name: 'Beer',
    svgPaths: [
      'M392,208H368v-5.74A63.93,63.93,0,0,0,321.65,96a111,111,0,0,0-27.59-47.29A108.62,108.62,0,0,0,216,16c-29.91,0-57.78,12.28-79,34.68a56,56,0,0,0-67.51,77.54A63.91,63.91,0,0,0,80,231.39V440a56.06,56.06,0,0,0,56,56H312a56.06,56.06,0,0,0,56-56v-8h24a72.08,72.08,0,0,0,72-72V280A72.08,72.08,0,0,0,392,208ZM176,416a16,16,0,0,1-32,0V256a16,16,0,0,1,32,0Zm64,0a16,16,0,0,1-32,0V256a16,16,0,0,1,32,0Zm64,0a16,16,0,0,1-32,0V256a16,16,0,0,1,32,0Zm16-224c-8.33,0-20.55-5.18-26.69-11.31A16,16,0,0,0,282,176H160a16,16,0,0,0-15,10.53C138.17,205.21,121.4,208,112,208a32,32,0,0,1,0-64c.09,0,9.12.34,16.4,5.8a16,16,0,1,0,19.2-25.6A63.69,63.69,0,0,0,112,112a63.55,63.55,0,0,0-14,1.57A24,24,0,0,1,120,80a23.78,23.78,0,0,1,19.38,9.84,51.35,51.35,0,0,1,4.71,7.9A16,16,0,0,0,176,96c0-6.77-3.61-15.17-10.76-25-.46-.63-1-1.25-1.45-1.86C178.39,55.44,196.64,48,216,48a76.86,76.86,0,0,1,55.23,23.18A80.2,80.2,0,0,1,292.61,142a16,16,0,0,0,12.73,18.71,16.29,16.29,0,0,0,3,.28,16,16,0,0,0,15.7-13A111.78,111.78,0,0,0,326,128.57,32,32,0,0,1,320,192ZM432,360a40,40,0,0,1-40,40H368V240h24a40,40,0,0,1,40,40Z'
    ],
    svgViewboxSize: 512
  },
  {
    id: '36',
    value: <BI.BiSolidDrink />,
    name: 'Drink',
    svgPaths: [
      'M20.832 4.555A1 1 0 0 0 20 3H4a1 1 0 0 0-.832 1.554L11 16.303V20H8v2h8v-2h-3v-3.697l7.832-11.748zm-2.7.445-2 3H7.868l-2-3h12.264z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '37',
    value: <PI.PiBeerBottle />,
    name: 'Wine',
    svgPaths: [
      'M216,24,152,72l-40,8L28.69,163.31a16,16,0,0,0,0,22.63l41.37,41.37a16,16,0,0,0,22.63,0L176,144l8-40,48-64'
    ],
    svgViewboxSize: 254
  },
  {
    id: '38',
    value: <MD.MdSanitizer />,
    name: 'Sanitize',
    svgPaths: [
      'M15.5,6.5C15.5,5.66,17,4,17,4s1.5,1.66,1.5,2.5C18.5,7.33,17.83,8,17,8S15.5,7.33,15.5,6.5z M19.5,15 c1.38,0,2.5-1.12,2.5-2.5c0-1.67-2.5-4.5-2.5-4.5S17,10.83,17,12.5C17,13.88,18.12,15,19.5,15z M13,14h-2v-2H9v2H7v2h2v2h2v-2h2V14z M16,12v10H4V12c0-2.97,2.16-5.43,5-5.91V4H7V2h6c1.13,0,2.15,0.39,2.99,1.01l-1.43,1.43C14.1,4.17,13.57,4,13,4h-2v2.09 C13.84,6.57,16,9.03,16,12z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '39',
    value: <BI.BiSolidWasher />,
    name: 'Washer',
    svgPaths: [
      'M4 22h16a1 1 0 0 0 1-1V5c0-1.654-1.346-3-3-3H6C4.346 2 3 3.346 3 5v16a1 1 0 0 0 1 1zM18 3.924a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-3 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zM12 7c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z',
      'M12.766 16.929c1.399-.261 2.571-1.315 3.023-2.665a3.853 3.853 0 0 0-.153-2.893.482.482 0 0 0-.544-.266c-.604.149-1.019.448-1.5.801-.786.577-1.765 1.294-3.592 1.294-.813 0-1.45-.146-1.984-.354l-.013.009a4.006 4.006 0 0 0 4.763 4.074z'
    ],
    svgViewboxSize: 24
  },
  {
    id: '40',
    value: <MD.MdCleaningServices />,
    name: 'Cleaner',
    svgPaths: ['M16,11h-1V3c0-1.1-0.9-2-2-2h-2C9.9,1,9,1.9,9,3v8H8c-2.76,0-5,2.24-5,5v7h18v-7C21,13.24,18.76,11,16,11z M19,21h-2v-3 c0-0.55-0.45-1-1-1s-1,0.45-1,1v3h-2v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H9v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3H5v-5 c0-1.65,1.35-3,3-3h8c1.65,0,3,1.35,3,3V21z'],
    svgViewboxSize: 24
  }
];

export const colorArray: DropdownOption[] = [
  {
    id: 1,
    value: `#5876a3`,
    name: ''
  },
  {
    id: 2,
    value: `#c73931`,
    name: ''
  },
  {
    id: 3,
    value: `#f04c42`,
    name: ''
  },
  {
    id: 4,
    value: `#9f5ab3`,
    name: ''
  },
  {
    id: 5,
    value: `#9245aa`,
    name: ''
  },
  {
    id: 6,
    value: `#0080b6`,
    name: ''
  },
  {
    id: 7,
    value: `#0098d8`,
    name: ''
  },
  {
    id: 8,
    value: `#00bc9d`,
    name: ''
  },
  {
    id: 9,
    value: `#00a085`,
    name: ''
  },
  {
    id: 10,
    value: `#00ae64`,
    name: ''
  },
  {
    id: 11,
    value: `#00cc76`,
    name: ''
  },
  {
    id: 12,
    value: `#f6c435`,
    name: ''
  },
  {
    id: 13,
    value: `#fa9c30`,
    name: ''
  },
  {
    id: 14,
    value: `#ee7e32`,
    name: ''
  },
  {
    id: 15,
    value: `#db541b`,
    name: ''
  },
  {
    id: 16,
    value: `#ffffff`,
    name: ''
  },
  {
    id: 17,
    value: `#CCCCCC`,
    name: ''
  },
  {
    id: 18,
    value: `#93a5a6`,
    name: ''
  },
  {
    id: 19,
    value: `#7e8c8d`,
    name: ''
  },
  {
    id: 20,
    value: `#000000`,
    name: ''
  },
  {
    id: 21,
    value: `#323232`,
    name: ''
  },
  {
    id: 22,
    value: `#0070d2`,
    name: ''
  },
  {
    id: 23,
    value: `#EB99B3`,
    name: ''
  },
  {
    id: 24,
    value: `#71ADDA`,
    name: ''
  }
];
