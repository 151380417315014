import { useMemo } from 'react';
import { useRole } from './authentication/hooks/user';
import { RoleType } from './authentication/roles';
import { useGetLocationListQuery, useGetMeQuery } from './gql/graphql.generated';

export const useMyLocations = () => {
  const role = useRole();
  const [{ data: myData }] = useGetMeQuery();
  const [{ data: locationsData }] = useGetLocationListQuery();

  const myLocations = myData?.myEmployee?.restaurants;
  const allLocations = locationsData?.locations;
  const myId = myData?.myEmployee?.identityId;

  if (!myLocations) {
    throw Error('The user is not assigned to any locations');
  }

  if (!allLocations) {
    throw Error('Could not get full location list');
  }

  return useMemo(() => {
    return allLocations.filter(location => {
      if (
        myLocations.find(myLoc => myLoc.id === location.id) ||
        [RoleType.Admin, RoleType.Super_Admin].includes(role)
      ) {
        return true;
      }

      const properties = JSON.parse(location.properties || '{}');
      if (properties.supervisorId === myId) {
        return true;
      }
    });
  }, [myLocations, allLocations, myId]);
};

export const useAllLocations = () => {
  const [{ data: locationsData }] = useGetLocationListQuery();
  const allLocations = locationsData?.locations;

  if (!allLocations) {
    throw Error('Could not get full location list');
  }

  return allLocations;
}
