import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export interface SelectOption {
  name: string;
  value?: any;
  color?: string;
}

interface Props {
  options: SelectOption[];
  value: any;
  setValue: (newValue: any) => void;
  readOnly?: boolean;
  className?: string;
  defaultValue?: string;
}

export const SelectInput: FC<Props> = ({ options, value, setValue, readOnly, className, defaultValue = '-' }) => {
  if (readOnly) {
    const selectedOption = options.find(opt => opt.value === value);

    return (
      <div className={twMerge(className, 'bg-background py-2 px-3 flex items-center border border-border rounded-md')}>
        {selectedOption?.name || defaultValue}
      </div>
    );
  }

  return (
    <select
      value={value}
      onChange={event => setValue(event.target.value)}
      className={twMerge(className, 'border-border rounded-md overflow-hidden text-ellipsis')}
    >
      {options.map(option => (
        <option
          key={option.value}
          value={option.value}
          style={{
            background: option.color,
            fontWeight: option.color ? 'bold' : undefined
          }}
        >
          {option.name}
        </option>
      ))}
    </select>
  );
};
