import { FC, PropsWithChildren } from 'react';
import { createClient, Provider, cacheExchange, fetchExchange, Exchange, subscriptionExchange } from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { DisabledPropertyName } from '@microsoft/applicationinsights-common';
import { useVariable } from '@softwareimaging/backstage';
import { useClientId, useRequestToken } from '../authentication/hooks/auth';
import { authExchange } from '@urql/exchange-auth';
import { useOrganisationId } from '../authentication/hooks/user';
import decode, { JwtPayload } from 'jwt-decode';
import { SubscriptionClient } from 'subscriptions-transport-ws';

export const dependencyReportingExchange: Exchange = ({ forward }) => {
  return operations => {
    // <-- The ExchangeIO function
    const operationResult = forward(operations);
    return operationResult;
  };
};

let gSubscriptionClient: SubscriptionClient;
const getSubscriptionClient = (wsUrl: string, tenantId: string, clientId: string, requestToken: () => Promise<string>) => {
  if (gSubscriptionClient !== undefined) return gSubscriptionClient;

  gSubscriptionClient = new SubscriptionClient(wsUrl, {
    reconnect: true,
    connectionParams: async () => {
      const token = await requestToken();
      return {
        Authorization: `Bearer ${token}`,
        'tenant-id': tenantId,
        'client-id': clientId
      };
    }
  });
  return gSubscriptionClient;
}

export const URQLProvider: FC<PropsWithChildren> = ({ children }) => {
  const apiUrl = useVariable('apiUrl');
  const wsUrl = useVariable('wsUrl');
  const requestToken = useRequestToken();
  const id = useOrganisationId();
  const clientId = useClientId();

  if (!apiUrl) throw new Error('apiUrl is not defined');
  if (!wsUrl) throw new Error('wsUrl is not defined');

  const subscriptionClient = getSubscriptionClient(wsUrl + '/graphql', id, clientId, requestToken);

  const client = createClient({
    url: apiUrl + '/graphql',
    suspense: true,
    exchanges: [
      devtoolsExchange,
      cacheExchange,
      dependencyReportingExchange,
      authExchange(async ({ appendHeaders }) => {
        let token: string = await requestToken();

        return {
          addAuthToOperation: operation => {
            return appendHeaders(operation, {
              Authorization: `Bearer ${token}`
            });
          },
          willAuthError: () => {
            const claims: JwtPayload = decode(token);
            const now = Date.now() / 1000;
            const expires = claims.exp ?? 0;
            const timeLeft = (expires - now) / 60;
            // Return true if the token expires in 2 minutes or less
            return timeLeft < 2;
          },
          didAuthError: error => {
            const validationError = error.graphQLErrors.some(e => e.extensions.code === 'VALIDATION_ERROR');
            return validationError;
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          refreshAuth: async () => {
            token = await requestToken();
          }
        };
      }),
      subscriptionExchange({
        forwardSubscription: request => subscriptionClient.request(request)
      }),
      fetchExchange
    ],
    fetchOptions: () => {
      const requestOptions: RequestInit = {
        headers: {
          'tenant-id': id,
          [DisabledPropertyName]: 'true'
        }
      };

      return requestOptions;
    }
  });

  return <Provider value={client}>{children}</Provider>;
};

export const handleError = (error: Error) => {
  error.stack = new Error().stack;
  throw error;
};
