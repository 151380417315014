import * as React from 'react';
import { Menu as HeadlessMenu } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

export interface ActiveProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

export const Action: React.FC<ActiveProps> = ({ children, className, ...props }) => {
  return (
    <button
      {...props}
      className={twMerge(
        'w-full px-4 py-2 group flex space-x-2 rounded-md items-center text-lg lg:text-xl text-primary font-base lg:font-bold',
        'hover:bg-blue-900 dark:hover:bg-white hover:bg-opacity-10 dark:hover:bg-opacity-5',
        className
      )}
    >
      {children}
    </button>
  );
};

export const DropdownAction: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => {
  return (
    <HeadlessMenu.Item>
      {({ active }) => (
        <Action {...props} active={active}>
          {children}
        </Action>
      )}
    </HeadlessMenu.Item>
  );
};
