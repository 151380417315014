import { FC } from 'react';
import { InputProps, withField } from './form/withField';

interface ToggleProps extends InputProps<boolean> {
  disabled?: boolean;
}

const Toggle: FC<ToggleProps> = ({ id, value, onChange, disabled }) => {
  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          disabled={disabled}
          id={id}
          checked={value}
          type="checkbox"
          className="sr-only"
          onChange={onChange ? () => onChange(!value) : event => event.preventDefault()}
        />
        <div className={`w-9 h-3 lg:w-10 lg:h-4 rounded-full shadow-inner ${
          disabled ? 'bg-gray-200' : 'bg-purple-400'
        }`}></div>
        <div
          className={`dot absolute w-5 h-5 lg:w-6 lg:h-6 bg-gray-100 rounded-full shadow ${
            value ? '-left-1' : 'right-5'
          } -top-1 transition`}
        ></div>
      </div>
    </label>
  );
};

export default Toggle;
export const ToggleField = withField<boolean>(Toggle);
