import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';

export interface ConfirmationStore {
  registerForConfirmation: (message: string) => void;
  unregisterForConfirmation: () => void;
  callback?: () => void;
  confirmationMessage?: string;
  confirmationRequested: boolean;
  confirmationNeeded: boolean;
  requestConfirmation: (callback: () => void) => void;
  clearRequest: () => void;
}

export const ConfirmationContext = createContext<StoreApi<ConfirmationStore> | null>(null);

export const ConfirmationProvider: FC<PropsWithChildren> = ({ children }) => {
  const store = useMemo(() => {
    return createStore<ConfirmationStore>()((set, get) => ({
      registerForConfirmation: (message: string) => {
        set({ confirmationMessage: message, confirmationNeeded: true });
      },
      unregisterForConfirmation: () => {
        set({
          callback: undefined,
          confirmationMessage: undefined,
          confirmationRequested: false,
          confirmationNeeded: false
        });
      },
      requestConfirmation: (callback: () => void) => {
        if (!get().confirmationNeeded) {
          // If there's nobody registered with the service, just proceed.
          callback();
          return;
        }

        set({
          callback: () => {
            callback();
            get().unregisterForConfirmation();
          },
          confirmationRequested: true
        });
      },
      clearRequest: () => {
        set({ confirmationRequested: false });
      },
      confirmationNeeded: false,
      confirmationRequested: false
    }));
  }, []);

  return <ConfirmationContext.Provider value={store}>{children}</ConfirmationContext.Provider>;
};

type SelectorReturn<S extends (s: ConfirmationStore) => any> = ReturnType<S>;
export function useConfirm<S extends (s: ConfirmationStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error('useLocalSettings must be used within a LocalSettingsProvider');
  }
  return useStore(context, selector);
}
