import { FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
  label?: string | ReactNode;
}

export const Label: FC<PropsWithChildren<LabelProps>> = ({ children, className, ...props }) => {
  return (
    <label {...props} className={twMerge('text-primary-std text-sm font-medium', className)}>
      {children}
    </label>
  );
};
