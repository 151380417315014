import { useEffect } from 'react';
import { useConfirm } from './ConfirmationService';

export const ConfirmationOnCloseManager = () => {
  const { confirmationMessage, needed } = useConfirm(state => ({
    confirmationMessage: state.confirmationMessage,
    needed: state.confirmationNeeded
  }));

  useEffect(() => {
    if (!needed) return;
    const handler = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = confirmationMessage;
    };
    window.addEventListener('beforeunload', handler);

    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [needed, confirmationMessage]);

  return null;
};
