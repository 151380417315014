import { strings } from "../translation/strings";

export enum LeaveMeasurementUnit {
  DAYS = 'days',
  HOURS = 'hours'
}

export const useLeaveMeasurementUnitOptions = () => {
  return [
    { value: LeaveMeasurementUnit.DAYS, name: strings.settings.manageLeave.units.days }
    //{ value: LeaveMeasurementUnit.HOURS, name: strings.settings.manageLeave.units.hours }
  ];
};

export enum LeaveLimitPeriod {
  ROSTER = 'rosterPeriod',
  DAY = 'day',
  WEEK = 'week',
  YEAR = 'year'
}

export const useLeavePeriodOptions = () => {
  return [
    { value: LeaveLimitPeriod.ROSTER, name: strings.settings.manageLeave.period?.rosterPeriod }
    //{ value: LeaveLimitPeriod.YEAR, name: strings.settings.manageLeave.period?.year }
  ];
};

export interface LeaveLimit {
  value: number;
  mandatory: boolean;
  period: LeaveLimitPeriod;
}

export interface LeaveLimitSettings {
  max: LeaveLimit;
  min: LeaveLimit;
  unit: LeaveMeasurementUnit;
  consecutiveMax: number;
  consecutiveOverride: boolean;
  availableInEss?: boolean;
}

export const defaultLimitSettings: LeaveLimitSettings = {
  unit: LeaveMeasurementUnit.DAYS,
  min: { value: 0, period: LeaveLimitPeriod.ROSTER, mandatory: false },
  max: { value: 0, period: LeaveLimitPeriod.ROSTER, mandatory: false },
  consecutiveMax: 0,
  consecutiveOverride: false,
  availableInEss: false
};

export interface LeaveProperties {
  displayName: string;
  systemCode: string;
  color: string;
  tapNumber: number;
}

export const defaultProperties: LeaveProperties = {
  displayName: '',
  systemCode: '',
  color: '',
  tapNumber: -1
};

export interface LeaveType {
  id: string;
  name: string;
  active: boolean;
  properties: LeaveProperties;
  limitSettings: LeaveLimitSettings;
}
