import gql from "graphql-tag";
import {useSubscription} from "urql";
import {useNavigate} from "react-router";
import {useAuthentication} from "@/services/authentication/AuthenticationProvider";
import {useClientId} from "@/services/authentication/hooks/auth";

const SESSION_STATE_SUBSCRIPTION = gql`
    subscription SessionStateSubscription {
        sessionState {
            clientId
            accessTokenEventType
        }
    }
`;

export const SessionMonitor = () => {
    const account = useAuthentication(state => state.account);
    const navigate = useNavigate();
    const clientId = useClientId();

    const handleSubscription = (previous: any, response: any) => {
        const sessionState = response.sessionState;
        if (sessionState.accessTokenEventType === "TokenRevoked" && sessionState.clientId === clientId) {
            navigate('/auth/signout');
        }
    };

    useSubscription({
            query: SESSION_STATE_SUBSCRIPTION,
            pause: !account
        },
        handleSubscription);

    return null;
};