import {FC, PropsWithChildren, createContext, useContext, useMemo, useCallback} from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { useMySettingQuery } from '../gql/graphql.generated';
import { strings } from '../translation/strings';
import { useCreateUpdateMySettings } from './useCreateUpdateMySettings';

interface LanguageProviderStore {
  language: string;
  setLanguage: (newLanguage: string) => Promise<void>;
}

export const LanguageProviderContext = createContext<StoreApi<LanguageProviderStore> | null>(null);

export const LanguageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [{ data }] = useMySettingQuery();
  const displayLanguage = data?.mySettings?.displayLanguage;
  const createUpdateMySettings = useCreateUpdateMySettings();

  const getDefaultLanguage = useCallback(() => {
    if (displayLanguage) {
      return displayLanguage as string;
    }

    return strings.getLanguage();
  }, [displayLanguage]);

  const store = useMemo(() => {
    strings.setLanguage(getDefaultLanguage());

    return createStore<LanguageProviderStore>()(set => ({
      language: getDefaultLanguage(),
      setLanguage: async newLanguage => {
        await createUpdateMySettings(newLanguage);
        set({ language: newLanguage });
        // Don't use location.reload(), which loads /index.html. We load '/index.html for desktop,
        // '/ess.html' for ESS using URL rewrite feature of CDN rule engine.
        // Use replace('/') instead.
        location.replace('/');
      }
    }));
  }, [getDefaultLanguage, createUpdateMySettings]);

  return <LanguageProviderContext.Provider value={store}>{children}</LanguageProviderContext.Provider>;
};

type SelectorReturn<S extends (s: LanguageProviderStore) => any> = ReturnType<S>;
export function useLanguageProvider<S extends (s: LanguageProviderStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(LanguageProviderContext);
  if (!context) {
    throw new Error('useSystemSettings must be used within a SystemSettingsProvider');
  }
  return useStore(context, selector);
}
