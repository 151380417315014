import { z } from 'zod';

export class CustomError {
  public name = 'CustomError';
  public code = 500;
  public message = 'An unknown error occurred';
  public stack?: string;

  constructor(message?: unknown) {
    // If the message is a string, set it as the error message
    if (typeof message === 'string') {
      this.message = message;
    }

    // If the message is an object, try to parse it as an API error response
    const error = SimpleErrorObject.safeParse(message);
    if (error.success) {
      this.message = error.data.message;
    }

    this.stack = Error().stack;
  }

  toJSON() {
    return {
      name: this.name,
      code: this.code,
      message: this.message,
      stack: this.stack
    };
  }
}

interface Response {
  status: number;
  body: unknown;
}

export class APIError extends CustomError {
  static fromResponse(response: Response) {
    switch (response.status) {
      case 400:
        return new BadRequestError(response.body);
      case 403:
        return new ForbiddenError(response.body);
      case 404:
        return new NotFoundError(response.body);
      case 500:
        return new CriticalError(response.body);
      default:
        return new APIError();
    }
  }
}

export class CriticalError extends CustomError {
  name = 'CriticalError';
  code = 500;
  message = 'An unknown error occurred';
}

export class BadRequestError extends CustomError {
  name = 'BadRequestError';
  code = 400;
  message = 'The request was malformed';
}

export class ForbiddenError extends CustomError {
  name = 'ForbiddenError';
  code = 403;
  message = 'You are not authorized to perform this action';
}

export class NotFoundError extends CustomError {
  name = 'NotFoundError';
  code = 404;
  message = 'The requested resource was not found';
}

const SimpleErrorObject = z.object({
  message: z.string()
});

export const failedResponses = {
  400: SimpleErrorObject,
  403: SimpleErrorObject,
  500: SimpleErrorObject
};
