import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { failedResponses } from '../errors/APIError';

const c = initContract();

const WebPushRegistration = z.object({
  installationId: z.string(),
  platform: z.string(),
  webPushChannel: z.object({
    endpoint: z.string(),
    p256dh: z.string(),
    auth: z.string()
  })
});

export type WebPushRegistration = z.infer<typeof WebPushRegistration>;

export default c.router({
  register: {
    method: 'PUT',
    path: '/notifications/installations',
    responses: {
      200: z.any(),
      ...failedResponses
    },
    body: WebPushRegistration
  },
  unregister: {
    method: 'DELETE',
    path: '/notifications/installations/:id',
    responses: {
      200: z.undefined(),
      ...failedResponses
    },
    body: z.undefined()
  }
});
