import { useVariable } from '@softwareimaging/backstage';
import {
  MutationFunction,
  QueryClient,
  QueryClientProvider,
  QueryKey,
  UseQueryResult,
  useMutation,
  useQueryClient as useOriginalQueryClient,
  useQuery
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ApiFetcherArgs, ClientArgs, InitClientReturn, initClient, tsRestFetchApi } from '@ts-rest/core';
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useRequestToken } from '../authentication/hooks/auth';
import { useTenantIdAsync } from '../authentication/hooks/user';
import { Variables } from '../backstage/config';
import contracts from './contracts';

const QueryContext = createContext<QueryClient | undefined>(undefined);
const ClientContext = createContext<InitClientReturn<typeof contracts, ClientArgs> | undefined>(undefined);

type RequestOptions = Omit<RequestInit, 'body'> & { body?: Record<string, unknown> | FormData };
export type QueryParams = [string] | [string, RequestOptions];

export const APIProvider: FC<PropsWithChildren> = ({ children }) => {
  const apiBaseUrl = useVariable<Variables>('apiUrl');
  if (!apiBaseUrl) throw new Error('Missing apiUrl');
  const requestToken = useRequestToken();
  const getTenantId = useTenantIdAsync();

  const client = useMemo(() => {
    return initClient(contracts, {
      baseUrl: apiBaseUrl + '/api',
      baseHeaders: {},
      api: async (args: ApiFetcherArgs) => {
        const headers = {
          ...args.headers
        };

        if (args.contentType === 'multipart/form-data') {
          headers['Content-Type'] = 'multipart/form-data';
        } else if (args.body instanceof Object) {
          headers['Content-Type'] = 'application/json';
        }

        try {
          const accessToken = await requestToken();

          if (!accessToken) throw new Error('Missing request token');

          if (accessToken) {
            headers['Authorization'] = `Bearer ${accessToken}`;
          }

          const tenantId = await getTenantId();

          if (!tenantId) throw new Error('Missing tenant id');

          if (tenantId) {
            headers['tenant-id'] = tenantId;
          }
        } catch (e) {
          console.warn(e);
          console.warn(`Access to get token failed but could be anonymous.`);
        }

        return tsRestFetchApi({
          ...args,
          headers
        });
      }
    });
  }, [apiBaseUrl, requestToken]);

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          suspense: true,
          retry: 1,
          staleTime: 1000 * 60 * 5,
          cacheTime: 1000 * 60 * 5,
          meta: {
            client
          }
        }
      }
    });
  }, [client]);

  return (
    <ClientContext.Provider value={client}>
      <QueryClientProvider client={queryClient} context={QueryContext}>
        <ReactQueryDevtools initialIsOpen={false} context={QueryContext} />
        {children}
      </QueryClientProvider>
    </ClientContext.Provider>
  );
};

export function useClient() {
  const client = useContext(ClientContext);
  if (!client) throw new Error('Client not initialised.');
  return client;
}

export function useQueryClient() {
  const queryClient = useOriginalQueryClient({ context: QueryContext });
  if (!queryClient) throw new Error('QueryClient not initialised.');
  return queryClient;
}

export function useAPIQuery<T>(
  key: QueryKey,
  queryFn: (client: InitClientReturn<typeof contracts, ClientArgs>) => Promise<T>
): [T, UseQueryResult<T>] {
  const result = useQuery<T>(
    key,
    async context => {
      if (!context?.meta?.client) throw new Error('Client not initialised.');
      const client = context.meta.client as InitClientReturn<typeof contracts, ClientArgs>;
      return await queryFn(client);
    },
    { context: QueryContext }
  );

  // Because we are using suspense, we can't return undefined unless specifically set with the T | undefined type
  const data = result.data as T;
  return [data, result];
}

export function useAPIMutation<V, U>(
  func: (client: InitClientReturn<typeof contracts, ClientArgs>, queryClient: QueryClient) => MutationFunction<U, V>
) {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation<U, unknown, V>({
    mutationFn: func(client, queryClient),
    context: QueryContext
  });
}
