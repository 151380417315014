import { useRequestToken } from '@/services/authentication/hooks/auth';
import { useOrganisationId } from '@/services/authentication/hooks/user';
import { useCallback } from 'react';

const buildGqlHeaderObj = (tenantId: string, token: string) => `{
  "tenant-id": "${tenantId}",
  "authorization": "Bearer ${token}"
}`;

export const copyToClipboard = (text: string) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(text);
  }
};

export const useGetGqlHeader = () => {
  const requestToken = useRequestToken();
  const id = useOrganisationId();

  return useCallback(async () => {
    const token = await requestToken();
    return buildGqlHeaderObj(id, token);
  }, [id, requestToken]);
};
