import { strings } from '@/services/translation/strings';
import { useCallback } from 'react';
import { LeaveType } from '../leaveManagement/leave.types';
import { useEssLeaveTypes, useLeaveTypes } from '../leaveManagement/LeaveTypesProvider';
import { useSystemSettings } from '../settings/SystemSettingsProvider';
import { FixedLeaveTypeId } from './availability.types';

export const AVAILABLE_COLOR = 'hsl(145, 74%, 41%)';

export const useMapToLeaveTypeId = () => {
  const advanced = useSystemSettings(s => s.settings.advancedLeaveManagement);

  return useCallback(
    (leaveTypeId?: string) => {
      if (leaveTypeId === FixedLeaveTypeId.ROSTER) {
        return FixedLeaveTypeId.ROSTER;
      }

      if (!leaveTypeId) {
        return undefined;
      }

      if (advanced) {
        return leaveTypeId;
      } else {
        // The default leave type is 'paid leave'.
        return FixedLeaveTypeId.PAID;
      }
    },
    [advanced]
  );
};

export interface AvailabilityType {
  label: string;
  preferenceLabel: string;
  name: string;
  description: string;
  color: string;
  leaveTypeId?: string;
}

const processLeaveTypes = (leaveTypes: LeaveType[], activeOnly?: boolean) => {
  const options: AvailabilityType[] = [
    {
      label: strings.ess.availability.mode.name.available,
      preferenceLabel: strings.ess.availability.mode.name.work,
      name: strings.ess.availability.mode.name.available,
      description: '',
      color: AVAILABLE_COLOR
    }
  ];

  leaveTypes?.forEach(leaveType => {
    if (leaveType.active || !activeOnly) {
      options.push({
        label: leaveType.name,
        preferenceLabel: leaveType.name,
        name: leaveType.properties.displayName ? leaveType.properties.displayName : leaveType.name,
        description:
          leaveType.id === FixedLeaveTypeId.ROSTER
            ? strings.ess.availability.mode.description.cannotEdit
            : strings.ess.availability.mode.description.taps(leaveType.properties.tapNumber),
        leaveTypeId: leaveType.id,
        color: leaveType.properties.color
      });
    }
  });

  return options;
};

export const useAvailabilityTypes = (activeOnly?: boolean) => {
  const leaveTypes = useLeaveTypes();

  return processLeaveTypes(leaveTypes, activeOnly);
};

export const useEssAvailabilityTypes = (activeOnly?: boolean) => {
  const leaveTypes = useEssLeaveTypes();

  return processLeaveTypes(leaveTypes, activeOnly);
};

export const useGetAvailabilityString = (leaveTypeId?: string, fullName?: boolean) => {
  const availabilityTypes = useAvailabilityTypes();

  const availType = availabilityTypes.find(type => type.leaveTypeId === leaveTypeId);

  if (!availType) {
    return '';
  }

  return availType.name.length > 5 && !fullName ? availType.name.substring(0, 4) : availType.name;
};
