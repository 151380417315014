import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { useGetOrganisationPropertiesQuery } from '../gql/graphql.generated';
import { SystemSettings, defaultSettings } from './systemSettings.types';
import { useGetBreakOffsets } from './useGetBreakOffsets';
import { useFlag } from '@softwareimaging/backstage';

interface SystemSettingsStore {
  settings: SystemSettings;
  setSettings: (newSettings: SystemSettings) => void;
}

export const SystemSettingsContext = createContext<StoreApi<SystemSettingsStore> | null>(null);

export const SystemSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const orgId = '1';

  if (!orgId) {
    throw Error('Organisation ID must be defined');
  }

  const [{ data }] = useGetOrganisationPropertiesQuery({ variables: { orgId: parseInt(orgId) } });
  const offsetData = useGetBreakOffsets();

  const allowMultipleAvailabilities = useFlag('allowMultipleAvailableTimes');

  const store = useMemo(() => {
    let settings: SystemSettings;

    if (!data?.getOrganisationProperties) {
      settings = { ...defaultSettings };
    } else {
      const properties = JSON.parse(data.getOrganisationProperties);
      if (properties.maxCustomTemplates !== undefined) {
        properties.maxCustomTemplates = parseInt(properties.maxCustomTemplates);
      }

      if (properties.splitShifts?.minSecondsBetween !== undefined) {
        properties.splitShifts.minSecondsBetween = parseInt(properties.splitShifts.minSecondsBetween);
      }

      if (properties.splitShifts?.maxNumber !== undefined) {
        properties.splitShifts.maxNumber = parseInt(properties.splitShifts.maxNumber);
      }

      if (properties.minTime !== undefined) {
        properties.minTime = parseInt(properties.minTime);
      }

      if (properties.maxTime !== undefined) {
        properties.maxTime = parseInt(properties.maxTime);
      }

      settings = {
        ...defaultSettings,
        ...properties
      };
    }

    if(offsetData) {
      settings.timeOffsetAfterLastBreak = offsetData.timeOffsetAfterLastBreak;
      settings.timeOffsetBeforeFirstBreak = offsetData.timeOffsetBeforeFirstBreak
    }

    if (!allowMultipleAvailabilities) {
      settings.maxTimePeriods = 1;
    }

    return createStore<SystemSettingsStore>()(set => ({
      settings,
      setSettings: settings => set({ settings })
    }));
  }, [data]);

  return <SystemSettingsContext.Provider value={store}>{children}</SystemSettingsContext.Provider>;
};

type SelectorReturn<S extends (s: SystemSettingsStore) => any> = ReturnType<S>;
export function useSystemSettings<S extends (s: SystemSettingsStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(SystemSettingsContext);
  if (!context) {
    throw new Error('useSystemSettings must be used within a SystemSettingsProvider');
  }
  return useStore(context, selector);
}

