import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { failedResponses } from '../errors/APIError';

const c = initContract();

export const FileSchema = z.instanceof(File);
export type FileSchema = z.infer<typeof FileSchema>;

export default c.router({
  uploadStaffSkills: {
    method: 'POST',
    path: '/uploads/updatestaffskills',
    contentType: 'multipart/form-data',
    responses: {
      200: z.string(),
      ...failedResponses
    },
    body: c.type<File>()
  }
});
