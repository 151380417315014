import { BackstageProvider } from '@/services/backstage/BackstageProvider';
import { FC, PropsWithChildren, Suspense } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { CriticalErrorBoundary } from '@/pages/errors/ErrorBoundary';
import { AppInsightsProvider } from '@/services/insights/AppInsightsProvider';
import { DebugProvider } from '@/services/insights/DebugProvider';

// OIDC
import { AuthenticationProvider } from '@/services/authentication/OIDCProvider';

// Authentication
import { Authenticated } from '@/pages/authentication/Authenticated';
import { Redirect } from '@/pages/authentication/Redirect';
import { SignIn } from '@/pages/authentication/SignIn';
import { SignOut } from '@/pages/authentication/SignOut';
import { SignOutRedirect } from '@/pages/authentication/SignOutRedirect';

import { SessionMonitor } from '@/services/SessionMonitor';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LoadingIndicator } from './components/LoadingIndicator';
import { ConfirmCheck } from './services/confirm/ConfirmCheck';
import { ConfirmationOnCloseManager } from './services/confirm/ConfirmationOnCloseManager';
import { ConfirmationProvider } from './services/confirm/ConfirmationService';
import { URQLProvider } from './services/gql/URQLProvider';
import { APIProvider } from './services/rest/APIProvider';
import { SystemSettingsProvider } from './services/settings/SystemSettingsProvider';
import { LeaveTypesProvider } from './services/leaveManagement/LeaveTypesProvider';

const App: FC<PropsWithChildren> = ({ children }) => {
  const queryClient = new QueryClient();

  return (
    <Suspense>
      <BackstageProvider>
        <DebugProvider>
          <DndProvider backend={HTML5Backend}>
            <AppInsightsProvider>
              <AuthenticationProvider>
                <APIProvider>
                  <BrowserRouter>
                    <CriticalErrorBoundary>
                      <ConfirmationProvider>
                        <ConfirmationOnCloseManager />
                        <ConfirmCheck>
                          <Suspense fallback={<LoadingIndicator />}>
                            <Routes>
                              <Route element={<Authenticated />}>
                                <Route
                                  element={
                                    <URQLProvider>
                                      <Toaster />
                                      <SessionMonitor />
                                      <QueryClientProvider client={queryClient}>
                                        <Suspense fallback={<LoadingIndicator />}>
                                          <SystemSettingsProvider>
                                            <LeaveTypesProvider>
                                              <Outlet></Outlet>
                                            </LeaveTypesProvider>
                                          </SystemSettingsProvider>
                                        </Suspense>
                                      </QueryClientProvider>
                                    </URQLProvider>
                                  }
                                >
                                  {children}
                                </Route>
                              </Route>
                              <Route path="auth/signout" element={<SignOut />} />
                              <Route path="signin-oidc" element={<Redirect />} />
                              <Route path="auth/signin" element={<SignIn />} />
                              <Route path="logout/callback" element={<SignOutRedirect />} />
                              <Route path="*" element={<p>Not found</p>} />
                            </Routes>
                          </Suspense>
                        </ConfirmCheck>
                      </ConfirmationProvider>
                    </CriticalErrorBoundary>
                  </BrowserRouter>
                </APIProvider>
              </AuthenticationProvider>
            </AppInsightsProvider>
          </DndProvider>
        </DebugProvider>
      </BackstageProvider>
    </Suspense>
  );
};

export default App;
