import { initContract } from '@ts-rest/core';

import download from './download';
import notifications from './notifications';
import upload from './upload';

const c = initContract();
export default c.router({
  notifications,
  download,
  upload
});
