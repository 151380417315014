import clsx from 'clsx';
import { FC, HTMLAttributes, ReactNode, useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { Label } from '../Label';

export const get = (path: string, value?: Record<string, any>, defaultValue?: any): any => {
  return path.split('.').reduce((acc, v) => {
    try {
      acc = acc?.[v];
    } catch (e) {
      return defaultValue;
    }
    return acc;
  }, value);
};

export interface InputProps<T> extends Omit<React.HTMLAttributes<HTMLElement>, 'onChange'> {
  value?: T;
  onChange: (value: T) => void;
  placeholder?: string;
  placeholderClass?: string;
  className?: string;
  readOnly?: boolean;
}

interface FieldProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  name: string;
  description?: string;
  label?: string | ReactNode;
  readOnly?: boolean;
  clean?: boolean;
  inputClassName?: string;
  required?: boolean;
  validator?: (value: any) => boolean;
  customError?: CustomFieldErrorParser;
  validationMessage?: string;
  customValidatorMessage?: string;
  onlyDigits?: boolean;
  onChange?: (newValue?: any) => void;
  [x: string]: any;
}

export interface CustomFieldErrorParser {
  primaryId: string;
  secondaryId: string;
  index: number;
}

export function withField<T>(Input: FC<InputProps<T>>, isGroup = false): FC<FieldProps> {
  const Field: FC<FieldProps> = ({
    className,
    id,
    name,
    label,
    description,
    clean = true,
    readOnly,
    labelClassName,
    inputClassName,
    onlyDigits,
    validationMessage,
    customValidatorMessage,
    validator,
    customError,
    required,
    onChange,
    ...props
  }) => {
    const { register, watch, formState, setValue, resetField } = useFormContext();

    useEffect(() => {
      const validate = (value: any) => {
        if (required && (!value || (Array.isArray(value) && value.length === 0))) {
          return 'required';
        }
        if (validator && !validator(value)) {
          return 'validate';
        }
        return true;
      };

      register(name, { validate });
    }, [name, register, required, validator]);

    const value = watch(name) as T;
    const error: FieldError | undefined = formState.errors[id] as FieldError | undefined;

    return (
      <div className={clsx('flex flex-col gap-1', className)}>
        {label && <Label className={twMerge(labelClassName)}>{label}</Label>}
        {description && <p>{description}</p>}
        <Input
          {...props}
          className={twMerge(inputClassName, 'w-full')}
          value={value}
          onChange={(v: any) => {
            let valueToSet = v;
            if (onlyDigits) {
              valueToSet = valueToSet.replace(/\D+/g, '');
            }
            if (valueToSet) {
              setValue(name, valueToSet, { shouldDirty: true });
            } else {
              resetField(name, {
                defaultValue: ''
              });
            }

            setValue(name, valueToSet, { shouldDirty: true });
            onChange && onChange(valueToSet);
          }}
          readOnly={readOnly}
        />
        {error?.type === 'validate' && (
          <p className="mt-2 text-xs md:text-sm text-red-600" id={`${name}-validator-error`}>
            {customValidatorMessage}
          </p>
        )}
        {error?.type === 'required' && (
          <p className="mt-2 text-xs md:text-sm text-red-600" id={`${name}-error`}>
            {validationMessage}
          </p>
        )}
        {/*This error occurs when the BE rejects a form submission (Eg/ duplicate email)*/}
        {error?.type === 'BEValidation' && (
          <p className="mt-2 text-xs md:text-sm text-red-600" id={`${name}-be-validation-error`}>
            {error.message ?? ''}
          </p>
        )}
        {error?.type === 'custom' && (
          <p className="mt-2 text-xs md:text-sm text-red-600" id={`${name}-custom-error`}>
            {error.message}
          </p>
        )}
      </div>
    );
  };
  return Field;
}
