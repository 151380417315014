import { getDaysInMonth, getDaysInWeek, getLastMonthDay, getNextMonthDay } from '@/helpers/dateHelper';
import { useMemo } from 'react';
import { useLocalSettings } from './LocalSettingsProvider';
import { useSystemSettings } from './SystemSettingsProvider';

export const useMonthDates = (viewingDate: Date) => {
  const startOfMonth = useSystemSettings(state => state.settings.monthStartDay);

  const start = useMemo(() => {
    return getLastMonthDay(viewingDate, startOfMonth);
  }, [startOfMonth, viewingDate]);

  const end = useMemo(() => {
    //TODO: startOfMonth is defined as number, but actually a string. Problems are in settings services/providers.
    // Converting startOfMonth to string is a short term solution. Setting services or providers must be fixed to return
    // objects matching their interfaces.
    const endOfMonth = String(startOfMonth) === '1' ? 31 : startOfMonth - 1;
    return getNextMonthDay(viewingDate, endOfMonth);
  }, [startOfMonth, viewingDate]);

  return { start, end };
};

export const useNextAvailabilityPeriod = (date?: Date) => {
  const availabilityDeadline = useSystemSettings(state => state.settings.availabilityDeadlineSettings.deadlineMonthDay);
  const availabilityDeadlineOptional = useSystemSettings(
    state => state.settings.availabilityDeadlineSettings.sendReminders
  );
  const startOfMonth = useSystemSettings(state => state.settings.monthStartDay);

  return useMemo(() => {
    const dateToUse = date ? date : new Date();
    const nextDeadline = getNextMonthDay(dateToUse, availabilityDeadline);
    const nextPeriodStart = getNextMonthDay(nextDeadline, startOfMonth);
    const nextPeriodEnd = getNextMonthDay(nextPeriodStart, startOfMonth - 1);

    return { nextPeriodStart, nextPeriodEnd, nextDeadline: availabilityDeadlineOptional ? undefined : nextDeadline };
  }, [startOfMonth, availabilityDeadline, availabilityDeadlineOptional, date]);
};

export const useDaysInCurrentMonth = () => {
  const { selectedDate } = useLocalSettings(state => ({
    selectedDate: state.selectedDate
  }));

  const startDay = useSystemSettings(state => state.settings.monthStartDay);

  return useMemo(() => getDaysInMonth(new Date(selectedDate), startDay), [selectedDate, startDay]);
};
