import { colorArray } from '@/helpers/dropdownOptionHelper';
import { ReadLeaveType, UpdateLeaveType } from '@/services/gql/graphql.generated';
import { FixedLeaveTypeId } from '../availability/availability.types';
import { LeaveType, LeaveProperties, LeaveLimitSettings, defaultLimitSettings, defaultProperties } from './leave.types';

// Patch up tap numbers that are out of sync due to leaves being hidden
export const patchUpTapNumbers = (leaveTypes: LeaveType[]) => {
  // Assumption that passed in leave types are sorted in the correct order.
  leaveTypes
    .filter(type => type.properties.tapNumber !== -1)
    .forEach((type, index) => {
      type.properties.tapNumber = index + 1;

      return type;
    });

  return leaveTypes;
};

export const convertFromGqlLeaveTypes = (gqlLeaveTypes?: ReadLeaveType[]) => {
  return gqlLeaveTypes?.map(lt => convertLeaveTypeFromGql(lt)) ?? [];
};

export const convertLeaveTypeFromGql = (gqlLeaveType: ReadLeaveType) => {
  let properties: LeaveProperties = { ...defaultProperties };
  const limitSettings: LeaveLimitSettings = { ...defaultLimitSettings };

  const rosterDayOff = gqlLeaveType.id === FixedLeaveTypeId.ROSTER;
  const unavailable = gqlLeaveType.id === FixedLeaveTypeId.PAID;
  // Initialise rdo or unavailable in case where color is missing
  if (rosterDayOff || (unavailable && !properties.color)) {
    properties.color = rosterDayOff ? colorArray[17].value : colorArray[23].value;
  }

  if (gqlLeaveType.properties) {
    const parsed = JSON.parse(gqlLeaveType.properties);

    properties = { ...properties, ...parsed };

    const parsedLimit = parsed.limitSettings as LeaveLimitSettings;
    if (parsedLimit) {
      limitSettings.unit = parsedLimit.unit;
      limitSettings.max = { ...limitSettings.max, ...parsedLimit.max };
      limitSettings.min = { ...limitSettings.min, ...parsedLimit.min };
      limitSettings.consecutiveOverride = parsedLimit.consecutiveOverride;
      limitSettings.consecutiveMax = parsedLimit.consecutiveMax;
      limitSettings.availableInEss = parsedLimit.availableInEss;
    }
  }

  const leave: LeaveType = {
    id: gqlLeaveType.id,
    name: gqlLeaveType.name,
    active: gqlLeaveType.active,
    properties,
    limitSettings
  };

  return leave;
};

export const convertLeaveTypeToGql = (leaveType: LeaveType, wipeId?: boolean) => {
  const gqlLeave: UpdateLeaveType = {
    id: wipeId ? undefined : leaveType.id,
    name: leaveType.name,
    active: leaveType.active,
    properties: JSON.stringify({
      ...leaveType.properties,
      limitSettings: leaveType.limitSettings
    })
  };

  return gqlLeave;
};
