import { Menu, Transition } from '@headlessui/react';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

export enum DropdownOrigin {
  TOP_LEFT = 'origin-top-left left-0',
  TOP_RIGHT = 'origin-top-right right-0'
}

export interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  button?: React.ReactNode;
  origin?: DropdownOrigin;
  className?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  button,
  children,
  origin = DropdownOrigin.TOP_LEFT,
  className
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {button ? <Menu.Button>{button}</Menu.Button> : null}
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={twMerge(
            origin,
            'absolute w-max rounded-md shadow-lg p-2 bg-background ring-1 ring-black ring-opacity-5 focus:outline-none z-[5000]',
            className
          )}
        >
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
