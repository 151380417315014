import { strings } from '@/services/translation/strings';
import { Button } from '../button';
import { Modal } from './Modal';
import { FC } from 'react';

export interface ConfirmationModalProps {
  open?: boolean;
  onClose: () => void;
  message: string;
  onConfirm: () => void;
  loading?: boolean;
  header?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  open,
  onClose,
  message,
  onConfirm,
  loading,
  header,
  confirmButtonText,
  cancelButtonText
}) => {
  return (
    <Modal onClose={onClose} open={open || false}>
      <div className="flex flex-col gap-2">
        {header && <div className="font-bold align-middle self-center">{header}</div>}
        <div className="flex flex-col gap-2">
          <p>{message}</p>
          <div className="flex justify-end gap-5 mt-5 items-center">
            <Button onClick={onClose}>{cancelButtonText ? cancelButtonText : strings.common.cancel}</Button>
            <Button loading={loading} onClick={onConfirm}>
              {confirmButtonText ? confirmButtonText : strings.common.ok}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
