import { FC, ReactNode } from 'react';
import { useConfirm } from './ConfirmationService';
import { ConfirmationModal } from '@/components/modal/ConfirmationModal';

interface Props {
  children: ReactNode;
}

export const ConfirmCheck: FC<Props> = ({ children }) => {
  const { callback, confirmationMessage, requested, clear } = useConfirm(state => ({
    callback: state.callback,
    confirmationMessage: state.confirmationMessage,
    requested: state.confirmationRequested,
    clear: state.clearRequest
  }));

  return (
    <>
      {children}
      {callback && confirmationMessage && (
        <ConfirmationModal onClose={clear} open={requested} message={confirmationMessage} onConfirm={callback} />
      )}
    </>
  );
};
