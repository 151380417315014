import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import * as React from 'react';

export interface ModalProps extends React.PropsWithChildren {
  open: boolean;
  onClose: () => void;
  title?: string;
}

export const Modal: React.FC<ModalProps> = ({ children, open, onClose, title }) => {
  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog as="div" static open={open} className="fixed inset-0 z-[100] overflow-y-auto" onClose={onClose}>
        <div className="overflow-hidden">
          <div className="pb-safe items-end justify-center text-center block sm:p-0 w-screen">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={clsx(
                  'w-full xl:w-max sm:w-5/6',
                  'bg-background inline-block transform p-5 text-left shadow-xl transition-all relative bottom-auto sm:rounded-lg sm:align-middle'
                )}
              >
                {title && <div className="text-xl font-semibold mb-5">{title}</div>}
                {children}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
