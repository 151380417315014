import { FC } from 'react';
import { strings } from '@/services/translation/strings';

interface Props {
  loadingLabel?: string;
  hideLoadingLabel?: boolean;
}

export const LoadingIndicator: FC<Props> = ({ loadingLabel, hideLoadingLabel }) => {
  return (
    <div className="absolute flex flex-col items-center justify-center content-center top-2/4 w-full">
      <img src="/circles.svg" alt="Notifications" width="40 lg:60" />
      {!hideLoadingLabel && (
        <span className="text-[#3d5067] font-bold text-lg mt-3">
          {loadingLabel ? loadingLabel : strings.common.loading}
        </span>
      )}
    </div>
  );
};
