import { dateToApiFormat } from '@/helpers/dateHelper';
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { useConfirm } from '../confirm/ConfirmationService';
import { Location } from '../gql/graphql.generated';
import { useMyLocations } from '../useLocations';

export interface LocalSettingsStore {
  selectedLocationId: number;
  setSelectedLocationId: (newLocationId: number) => void;
  viewableLocationIds: number[];
  setViewableLocationIds: (newLocationIds: number[]) => void;
  selectedDate: string;
  setSelectedDate: (newDate: string) => void;
  favouriteTaskIds: string[];
  toggleFavouriteTaskId: (taskId: string) => void;
}

export const LocalSettingsContext = createContext<StoreApi<LocalSettingsStore> | null>(null);

export const LocalSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const locationsList = useMyLocations();
  const { requestConfirmation } = useConfirm(state => ({ requestConfirmation: state.requestConfirmation }));

  const store = useMemo(() => {
    return createStore<LocalSettingsStore>()(
      persist<LocalSettingsStore>(
        (set, get) => ({
          selectedLocationId: locationsList[0]?.id,
          setSelectedLocationId: (newId: number) => {
            requestConfirmation(() => set({ selectedLocationId: newId }));
          },
          viewableLocationIds: locationsList.map(loc => loc.id),
          setViewableLocationIds: (newIds: number[]) => {
            requestConfirmation(() => {
              if (newIds.length === 0) {
                throw Error('Cannot set no locations viewable');
              }

              const currentSelectedId = get().selectedLocationId;
              if (newIds.includes(currentSelectedId)) {
                set({ viewableLocationIds: newIds });
              } else {
                set({ viewableLocationIds: newIds, selectedLocationId: newIds[0] });
              }
            });
          },
          selectedDate: dateToApiFormat(new Date()),
          setSelectedDate: (newDate: string) => {
            requestConfirmation(() => set({ selectedDate: newDate }));
          },
          favouriteTaskIds: [],
          toggleFavouriteTaskId: (taskId: string) => {
            const ids = [...get().favouriteTaskIds];

            if (!ids.includes(taskId)) {
              ids.push(taskId);
            } else {
              const index = ids.findIndex(id => id === taskId);
              ids.splice(index, 1);
            }

            set({ favouriteTaskIds: ids });
          }
        }),
        {
          name: 'settings',
          version: 2,
          onRehydrateStorage: () => {
            return newState => {
              if (!newState) {
                return;
              }

              if (!locationsList.find(location => location.id === newState.selectedLocationId)) {
                newState.selectedLocationId = locationsList[0].id;
              }

              const removedLocationIds = newState.viewableLocationIds.filter(
                id => !locationsList.find(location => location.id === id)
              );

              newState.viewableLocationIds = newState.viewableLocationIds.filter(
                id => !removedLocationIds.includes(id)
              );

              if (newState.viewableLocationIds.length === 0) {
                newState.viewableLocationIds = [newState.selectedLocationId];
              }
            };
          }
        }
      )
    );
  }, [locationsList, requestConfirmation]);

  return <LocalSettingsContext.Provider value={store}>{children}</LocalSettingsContext.Provider>;
};

type SelectorReturn<S extends (s: LocalSettingsStore) => any> = ReturnType<S>;
export function useLocalSettings<S extends (s: LocalSettingsStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(LocalSettingsContext);
  if (!context) {
    throw new Error('useLocalSettings must be used within a LocalSettingsProvider');
  }
  return useStore(context, selector);
}

export const useLocationDetails = (locationId: number) => {
  const locations = useMyLocations();

  const selectedLocation = locations.find(location => location.id === locationId);

  return selectedLocation as Location;
};

export const useSelectedLocation = () => {
  const { selectedLocationId, setSelectedLocationId } = useLocalSettings(state => ({
    selectedLocationId: state.selectedLocationId,
    setSelectedLocationId: state.setSelectedLocationId
  }));

  const locations = useMyLocations();

  let selectedLocation = locations.find(location => location.id === selectedLocationId);

  if (!selectedLocation) {
    setSelectedLocationId(locations[0].id);
    selectedLocation = locations[0];
  }

  return selectedLocation as Location;
};
