import { useCreateMySettingsMutation, useMySettingQuery, useUpdateMySettingsMutation } from '../gql/graphql.generated';

export const useCreateUpdateMySettings = () => {
  const [, update] = useUpdateMySettingsMutation();
  const [, create] = useCreateMySettingsMutation();

  const [{ data }] = useMySettingQuery();

  const createUpdate = async (language: string) => {
    if (data?.mySettings) {
      await update({
        settings: {
          displayLanguage: language
        }
      });
    } else {
      await create({
        settings: {
          displayLanguage: language
        }
      });
    }
  };

  return createUpdate;
};
