import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { failedResponses } from '../errors/APIError';

const c = initContract();

const StaffSkillBody = z.object({
  locationIds: z.array(z.number())
});

export type DownloadStaffSkillPayload = z.infer<typeof StaffSkillBody>;

export default c.router({
  downloadStaffSkills: {
    method: 'POST',
    path: '/Downloads/DownloadStaffSkills',
    responses: {
      200: z.string(),
      ...failedResponses
    },
    body: StaffSkillBody
  }
});
