import * as React from 'react';

export interface CustomDividerProps {
  width?: string | number;
  height?: string | number;
  thickness?: string | number;
  color?: string;
  style?: React.CSSProperties;
}

const CustomDivider: React.FC<CustomDividerProps> = ({
  width = '100%',
  height = 1,
  thickness = 1,
  color = 'hsla(0, 0%, 20%, 0.25)',
  style
}) => (
  <div
    style={{
      width,
      height,
      borderBottom: `${thickness}px solid ${color}`,
      margin: '10px 0',
      ...style
    }}
  />
);

export default CustomDivider;
