import { useGetLocationQuery, useGetMeQuery } from './gql/graphql.generated';
import { Location } from './gql/graphql.generated';

export const useMyLocation = () => {
  const [{ data: myData }] = useGetMeQuery();

  const myLocationId = myData?.myEmployee?.homeLocationId;

  if (!myLocationId) {
    return undefined;
  }

  const [{ data: locationData}] = useGetLocationQuery({variables: {id: myLocationId}, requestPolicy: 'network-only'});

  const location = locationData?.location;

  if (!location) {
    throw Error("User's location not found");
  }

  return location as Location;
};
