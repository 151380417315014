import { useAuthentication } from '../AuthenticationProvider';
import { RoleType } from '../roles';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

const InProgressCache = new Map<string, Promise<unknown>>();

const withCache = async <T extends void | string = void>(key: string, func: () => Promise<T>): Promise<T> => {
  if (InProgressCache.has(key)) {
    const promise = InProgressCache.get(key) as Promise<T>;
    if (!promise) throw new Error('Promise is undefined');
    return promise;
  }
  const action = func();
  InProgressCache.set(key, action);
  action.finally(() => {
    InProgressCache.delete(key);
  });
  return await action;
};

export const useOrganisationId = (): string => {
  const organisationId = useAuthentication(state => state.account?.claims?.organisation as string);
  if (!organisationId) throw new Error('User is not authenticated');
  return organisationId;
};

export const useRole = () => {
  const role = useAuthentication(
    state => state.account?.claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] as RoleType
  );

  if (!role) throw new Error('User does not have a role defined');
  return role;
};

export const useUser = (): User => {
  const account = useAuthentication(state => state.account);

  if (!account) throw new Error('Missing accounts');
  const { id, firstName, lastName, email } = account;

  return {
    id,
    firstName,
    lastName,
    email
  };
};

export const useTenantIdAsync = () => {
  const getOrgId = useAuthentication(state => state.getTenantId);

  return async () => await withCache<string>('requestToken', async () => getOrgId() as string);
};
