import {useCallback, useRef} from 'react';
import {
  useGetNotificationsQuery,
} from "@/services/gql/graphql.generated";
import {convertDateToGQLFormat} from "@/helpers/dateHelper";

export enum NotificationTriggerType {
  VACANT_SHIFT = 1
}

export const useGetUnifiedNotification = (locationId: number, triggerType: NotificationTriggerType, days: Date[]) => {
  // This hook is currently used with a Toggle component, which re-render itself when toggled/untoggled.
  // So, we use useRef. it's not needed to use useState to trigger the component re-render.
  const ref = useRef<boolean|undefined>(undefined);
  const [{ data }, refetch] = useGetNotificationsQuery({
    variables: {
      notifications: {
        locationId: locationId,
        triggerType: triggerType,
        startDate: convertDateToGQLFormat(days[0]),
        endDate: convertDateToGQLFormat(days[days.length - 1]),
      }
    },
    requestPolicy: 'network-only'
  });

  const notifications = data?.locationNotificationsForDateRange;
  if (!notifications) {
    throw Error('Could not get notifications');
  }

  if (ref.current === undefined) {
    let countNotificationOns = 0;
    notifications.forEach(n => {
      if (n.value) {
        countNotificationOns++;
      }
    });
    if (countNotificationOns === days.length) {
      ref.current = true;
    } else if (countNotificationOns === 0) {
      ref.current = false;
    } else {
      // this could be caused by changing the start date of monthly roster.
      console.log('Invalid notification states detected.');
      ref.current = false;
    }
  }

  const setUnifiedNotification = useCallback((newValue: boolean) => {
    refetch();
    ref.current = newValue;
  }, [refetch]);

  const getNotificationProperties = useCallback((dateString: string) => {
    return notifications.find(n => n.date === dateString)?.properties;
  }, [notifications]);

  const notificationAlreadySent = useCallback(() => {
    return notifications.find(n => {
      if (n.properties) {
        const properties = JSON.parse(n.properties);
        return properties?.sent === true;
      }
      return false;
    }) !== undefined;
  }, [notifications]);

  return {
    unifiedNotification: ref.current,
    setUnifiedNotification,
    getNotificationProperties,
    notificationAlreadySent};
};
