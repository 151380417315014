import { FC, PropsWithChildren } from 'react';
import { InputProps, withField } from './withField';
import { twMerge } from 'tailwind-merge';
import React from 'react';

export const TextInput = React.forwardRef<HTMLInputElement, PropsWithChildren<InputProps<string>>>(
  ({ value, onChange, className, placeholder, placeholderClass, readOnly, ...props }, ref) => {
    return (
      <input
        ref={ref}
        type="text"
        className={twMerge(
          'block w-full pb-2 pt-2 px-2 truncate rounded-md font-medium shadow-sm border-gray-300',
          readOnly
            ? 'bg-background-mid text-primary-50 cursor-default border-gray-200 focus:border-gray-200 focus:ring-0'
            : 'bg-secondary-std text-secondary-std',
          className + (placeholder && placeholderClass ? ' ' + placeholderClass : '')
        )}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        readOnly={readOnly}
        {...props}
      />
    );
  }
);

export const TextField = withField(TextInput);
