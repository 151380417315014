import { strings } from '@/services/translation/strings';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { FIXED_LEAVE_TYPES, FixedLeaveTypeId } from '../availability/availability.types';
import { ReadLeaveType, useCreateLeaveTypesMutation, useDeleteLeaveTypesMutation, useGetLeaveTypesQuery, useUpdateLeaveTypesMutation } from '../gql/graphql.generated';
import { useSystemSettings } from '../settings/SystemSettingsProvider';
import { LeaveType } from './leave.types';
import { convertFromGqlLeaveTypes, convertLeaveTypeToGql, patchUpTapNumbers } from './leaveManagementHelper';

export interface LeaveTypesStore {
  leaveTypes: LeaveType[];
  refetch: () => void;
}

export const LeaveTypesContext = createContext<LeaveTypesStore>({ leaveTypes: [], refetch: () => {} });

export const LeaveTypesProvider: FC<PropsWithChildren> = ({ children }) => {
  const advanced: boolean = useSystemSettings(state => state.settings.advancedLeaveManagement);

  const [{ data }, refetch] = useGetLeaveTypesQuery();

  const store = useMemo(() => {
    if (!data?.leaveTypes) {
      throw Error('Unable to get leave types');
    }
  
    const rosterDayOff = data.leaveTypes.find(lt => lt.id === FixedLeaveTypeId.ROSTER);
    const unavailable = data.leaveTypes.find(lt => lt.id === FixedLeaveTypeId.PAID);
  
    if (!rosterDayOff || !unavailable) {
      throw Error('Unable to get Paid and RDO leave types, which must exist in all SnpsShift tenant databases');
    }
  
    const basicLeaveTypes: ReadLeaveType[] = [
      rosterDayOff,
      {
        ...unavailable,
        name: strings.ess.availability.mode.name.unavailable // rename 'paid' to 'unavailable'
      }
    ];
  
    let leaveTypes: ReadLeaveType[];
    if (advanced) {
      const advancedTypes = data.leaveTypes.filter(l => !FIXED_LEAVE_TYPES.includes(l.id));
      leaveTypes = [...basicLeaveTypes, ...advancedTypes];
    } else {
      leaveTypes = basicLeaveTypes;
    }
    
    return { leaveTypes: convertFromGqlLeaveTypes(leaveTypes), refetch };  
  }, [data, advanced]);

  return <LeaveTypesContext.Provider value={store}>{children}</LeaveTypesContext.Provider>;
};

export const useLeaveTypes = () => {
  const context = useContext(LeaveTypesContext);

  if (context.leaveTypes.length === 0) {
    throw Error("useLeaveManagement must be used within a LeaveTypesProvider");
  }
  
  return context.leaveTypes;
}

export const useRefreshLeaveTypes = () => {
  const context = useContext(LeaveTypesContext);
  
  return context.refetch;
}

export const useEssLeaveTypes = () => {
  const leaveTypes = useLeaveTypes();

  return useMemo(() => {
    const filteredTypes = leaveTypes.filter(lt => {
      let valid = lt.active;

      if (!(FIXED_LEAVE_TYPES.includes(lt.id) || lt.limitSettings.availableInEss)) {
        valid = false;
      }

      return valid;
    });
    const sorted = filteredTypes.sort((a, b) => {
      const aTap = a.properties.tapNumber;
      const bTap = b.properties.tapNumber;

      if (aTap === -1) {
        return -1;
      }
      if (bTap === -1) {
        return 1;
      } else {
        return aTap - bTap;
      }
    });

    return patchUpTapNumbers(sorted);
  }, [leaveTypes]);
};

export const useLeaveType = (leaveTypeId?: string) => {
  const leaveTypes = useLeaveTypes();

  const leaveType = leaveTypes.find(lt => lt.id === leaveTypeId);

  if (leaveTypeId && !leaveType) {
    throw Error(`Could not find leave type ${leaveTypeId}`);
  }

  return leaveType;
};

export const useCreateLeaveTypes = () => {
  const [{ fetching }, create] = useCreateLeaveTypesMutation();
  const createLeaveTypes = useCallback(async (leaveTypes: LeaveType[]) => {
    const result = await create({ leaveTypes: leaveTypes.map(lt => convertLeaveTypeToGql(lt, true)) });

    return result;
  }, []);

  return { fetching, createLeaveTypes };
};

export const useUpdateLeaveTypes = () => {
  const [{ fetching }, update] = useUpdateLeaveTypesMutation();
  const updateLeaveTypes = useCallback(async (leaveTypes: LeaveType[]) => {
    const result = await update({ leaveTypes: leaveTypes.map(lt => convertLeaveTypeToGql(lt)) });

    return result;
  }, []);

  return { fetching, updateLeaveTypes };
};

export const useDeleteLeaveTypes = () => {
  const [{ fetching }, mutation] = useDeleteLeaveTypesMutation();

  const deleteLeaveTypes = useCallback(
    async (ids: string[]) => {
      const idsToDelete = ids.filter(id => !FIXED_LEAVE_TYPES.includes(id));

      return await mutation({ ids: idsToDelete });
    },
    [mutation]
  );

  return { deleteLeaveTypes, fetching };
};