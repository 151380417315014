import { useVariable } from '@softwareimaging/backstage';
import { OidcClient, SigninResponse, WebStorageStateStore } from 'oidc-client-ts';
import { FC, PropsWithChildren, useMemo } from 'react';
import { createStore } from 'zustand';
import { Variables } from '../backstage/config';
import { useDebug } from '../insights/DebugProvider';
import {
  Account,
  AuthenticationContext,
  AuthenticationInformation,
  AuthenticationStatus,
  AuthenticationStore
} from './AuthenticationProvider';

export interface OIDCAuthenticationStore extends AuthenticationStore {
  details?: SigninResponse;
}

const OIDC_SIGNIN_RESPONSE_KEY = 'oidc-signin-response';

// It is GDPR compliant to use sessionStorage instead of localStorage here.
// If you need to persist the login, you can use localStorage instead but you will need to
// add a consent dialog to your application. This can be as simple as a checkbox that says
// "Remember me".
const storage: Storage = window.sessionStorage;

const getAccountDetails = (response: SigninResponse): Account => {
  if (!response.profile.email) throw new Error('Missing profile');

  const [, name] = response.profile.name as unknown as string[];
  const [firstName, lastName] = name.split(' ');

  return {
    id: response.profile.sub,
    firstName,
    lastName,
    email: response.profile.email,
    claims: response.profile
  };
};

export const AuthenticationProvider: FC<PropsWithChildren> = ({ children }) => {
  const authUrl = useVariable<Variables>('authUrl');
  const clientIdsJson = useVariable<Variables>('clientIds');

  const log = useDebug('AuthenticationProvider');

  const clientId = useMemo(() => {
    if (clientIdsJson) {
      const clientIds = JSON.parse(clientIdsJson);
      if (clientIds) {
        return clientIds[window.location.origin];
      }
    }
    return '';
  }, [clientIdsJson]);

  if (!authUrl || !clientId.length) {
    throw new Error('Missing authentication configuration');
  }

  const client = useMemo(() => {
    log.info(`Creating OIDC client for ${authUrl}`);
    return new OidcClient({
      authority: authUrl,
      client_id: clientId,
      redirect_uri: `${window.location.origin}/signin-oidc`,
      post_logout_redirect_uri: `${window.location.origin}/logout/callback`,
      response_type: 'code',
      scope: 'openid profile email api.read offline_access IdentityServerApi',
      filterProtocolClaims: true,
      loadUserInfo: true,
      metadataUrl: `${authUrl}/.well-known/openid-configuration`,
      stateStore: new WebStorageStateStore({ store: storage, prefix: 'snpsoidc' }),
      extraQueryParams: {
        setPasswordRedirect: window.location.origin,
        validation: Math.floor(Date.now() / 1000) // seconds since epoch
      }
    });
  }, [authUrl, clientId, log]);

  const store = useMemo(() => {
    const restoreAuthentication = (): AuthenticationInformation & { details?: SigninResponse } => {
      log.info('Restoring authentication');
      const response = storage.getItem(OIDC_SIGNIN_RESPONSE_KEY);
      if (response) {
        log.info('Restored authentication');
        const parsedResponse = JSON.parse(response) as SigninResponse;

        return {
          status: AuthenticationStatus.Authenticated,
          account: getAccountDetails(parsedResponse),
          details: parsedResponse
        };
      }

      log.info('No authentication to restore');
      return {
        status: AuthenticationStatus.NotAuthenticated
      };
    };

    return createStore<OIDCAuthenticationStore>((set, get) => ({
      ...restoreAuthentication(),
      getRequestToken: async () => {
        log.info('Getting request token');
        await client.clearStaleState();

        const { signInUp, details } = get();
        try {
          if (!details || !details.refresh_token) throw new Error('No response');

          const now = Date.now() / 1000;
          const expires = details.expires_at ?? 0;
          const timeLeft = (expires - now) / 60;
          if (timeLeft > 5) {
            log.info('Using existing token');
            return details.access_token;
          }

          log.info('Getting new token with refresh token');

          const response = await client.useRefreshToken({
            state: {
              profile: details.profile,
              refresh_token: details.refresh_token,
              session_state: details.session_state,
              id_token: details.id_token,
              data: undefined
            }
          });
          log.info(`Got new token with refresh token`);
          set({
            status: AuthenticationStatus.Authenticated,
            details: response
          });
          storage.setItem(OIDC_SIGNIN_RESPONSE_KEY, JSON.stringify(response));
          return response.access_token;
        } catch (e) {
          log.error('Failed to get new token with refresh token');
          await signInUp();
          throw e;
        }
      },
      onRedirect: async () => {
        const response = await client.processSigninResponse(window.location.href);
        if (response) {
          set({
            status: AuthenticationStatus.Authenticated,
            account: getAccountDetails(response),
            details: response
          });
          storage.setItem(OIDC_SIGNIN_RESPONSE_KEY, JSON.stringify(response));
          if (!response.userState) return;
          return response.userState as string;
        } else {
          set({ status: AuthenticationStatus.NotAuthenticated });
        }
      },
      signInSilent: async () => {
        set({ status: AuthenticationStatus.Authenticating });
        // Can the user be silently authenticated?
        set({
          // status: AuthenticationStatus.Authenticated,
          // account: getAccountDetails(account)
        });
      },
      signInUp: async (from?: string) => {
        await client.clearStaleState();
        set({ status: AuthenticationStatus.Authenticating });
        const request = await client.createSigninRequest({ state: from });
        window.location.replace(request.url);
      },
      signOut: async () => {
        await client.clearStaleState();

        const { details } = get();
        const request = await client.createSignoutRequest({ id_token_hint: details?.id_token });
        storage.removeItem(OIDC_SIGNIN_RESPONSE_KEY);
        window.location.replace(request.url);
      },
      getTenantId: () => {
        const response = storage.getItem(OIDC_SIGNIN_RESPONSE_KEY);

        if (!response) throw new Error('Missing sign in response');

        const parsedResponse = JSON.parse(response) as SigninResponse;
        const accountDetails = getAccountDetails(parsedResponse);

        return accountDetails.claims.organisation as string;
      },
      clientId: clientId
    }));
  }, [client, clientId, log]);

  return <AuthenticationContext.Provider value={store}>{children}</AuthenticationContext.Provider>;
};
