import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

export interface FormProps extends Omit<React.HTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  methods: UseFormReturn<any>;
  onSubmit: SubmitHandler<any>;
}

export const Form: FC<PropsWithChildren<FormProps>> = ({ children, methods, onSubmit, className, ...props }) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={clsx('', className)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};
